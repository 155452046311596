import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { GetLibraryItemsQuery } from '@app/data'

type LibraryDataStoreStoreType = {
  libraryData: GetLibraryItemsQuery['libraryItems']
  isLibraryVisible: boolean
}

const initialState: LibraryDataStoreStoreType = {
  libraryData: {
    filters: {}
  },
  isLibraryVisible: import.meta.env.VITE_ENVIRONMENT === 'development'
}

const useStore = createWithEqualityFn<LibraryDataStoreStoreType>(() => initialState, shallow)

export const libraryDataStore = {
  setLibraryData: (
    libraryData:
      | GetLibraryItemsQuery['libraryItems']
      | ((prev: GetLibraryItemsQuery['libraryItems']) => GetLibraryItemsQuery['libraryItems'] | undefined)
  ): void => {
    if (typeof libraryData === 'function') {
      useStore.setState((prev) => {
        return {
          libraryData: libraryData(prev.libraryData)
        }
      })
      return
    } else {
      useStore.setState({ libraryData })
    }
  },

  setIsLibraryVisible: (isLibraryVisible?: boolean) => {
    useStore.setState((prev) => {
      return {
        ...prev,
        isLibraryVisible: isLibraryVisible !== undefined ? isLibraryVisible : !prev.isLibraryVisible
      }
    })
  },

  clearStore: (): void => {
    useStore.setState(initialState)
  },
  useStore
}
