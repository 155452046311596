import { Subject } from '@app/data'
import { SocialGameTypeSorted } from '@app/modules/common/model/enums'

export const Pages = {
  ROOT: '/',
  LOGIN: '/login',
  SETTINGS: '/settings',

  // Games
  GAMES: '/games',
  GAMES_TYPE: (type: SocialGameTypeSorted) => `/games/${type}`,

  // Library
  DICTIONARY: (language: string) => `/dictionary/${language}`,
  LIBRARY: (language: string) => `/library/${language}`,
  LIBRARY_COLLECTION_DETAIL: (language: string, id: string) => `/library/${language}/collection/${id}`,
  // Generator
  LIBRARY_GENERATOR: (language: string) => `/library/${language}/generator`,

  // Classroom
  CLASSROOMS: '/classrooms',
  NEW_CLASS: '/classrooms/new-class',
  CLASSROOM_DETAIL: (id: string) => `/classrooms/${id}`,
  CLASSROOM_LEARNING_PLAN: (id: string) => `/classrooms/${id}/learning-plan`,
  CLASSROOM_QUIZ_EDITOR: (id: string, activityId: string, subject: Subject) =>
    `/classrooms/${id}/quiz-editor/${subject}/${activityId}`,
  CLASSROOM_ACTIVITIES: (id: string) => `/classrooms/${id}/activities`,
  CLASSROOM_PERFORMANCE: (id: string) => `/classrooms/${id}/performance`,
  CLASSROOM_MANAGE_CLASS: (id: string) => `/classrooms/${id}/manage-class`,

  MAINTENANCE: '/maintenance'
} as const
