import { useEffect } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import { Button } from '@app/components'
import { useTranslation } from '@app/locales'

export const ErrorElement = () => {
  const { t } = useTranslation('common')
  const error = useRouteError()

  let errorMessage: string,
    jsonText: string = ''

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data.message
    jsonText = JSON.stringify(error.data, null, 2)
  } else if (error instanceof Error) {
    errorMessage = error.message
    jsonText = JSON.stringify(error, null, 2)
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    console.error(error)
    errorMessage = 'Unknown error'
  }

  const handleOnRefreshClick = () => {
    window.location.href = '/'
  }

  // Log the error object for more information
  console.error('Error details:', error)

  useEffect(() => {
    localStorage.clear()
  }, [])

  return (
    <div id="error-page" className="flex h-screen flex-col items-center justify-center gap-8">
      <h1 className="text-4xl font-bold">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p className="text-slate-400">
        <i>{errorMessage}</i>
        <pre className="text-slate-400">{jsonText}</pre>
      </p>
      <div>
        <Button className="h-min" onClick={handleOnRefreshClick}>
          {t('back')}
        </Button>
      </div>
    </div>
  )
}
