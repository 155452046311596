import { useNavigate } from 'react-router-dom'

import { Pages } from '@app/config/router/PagesEnum'
import {
  ClassroomInput,
  GetClassroomDetailDocument,
  GetClassroomDetailQuery,
  GetClassroomDetailQueryVariables,
  GetClassroomsDocument,
  GetClassroomsQuery,
  GetClassroomsQueryVariables,
  useCreateClassroomMutation,
  useDeleteClassroomMutation,
  useEditClassroomMutation,
  useGetClassroomDetailQuery
} from '@app/data'
import { QueryHookOptions } from '@app/data/graphqlHooks'
import { learningPlanStore } from '@app/modules/learningPlan/store/learningPlanStore'
import { LogUtils } from '@app/utils/LogUtils'

export const useClassroomDetail = (
  classroomId: string | undefined,
  baseOptions?: QueryHookOptions<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>
) => {
  const navigate = useNavigate()
  const { data, ...restOfResult } = useGetClassroomDetailQuery({
    variables: { classroomId: classroomId || '' },
    skip: !classroomId && !baseOptions?.skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    ...baseOptions
  })
  const [editClassroomMutation, editClassroomMutationData] = useEditClassroomMutation()
  const [createClassroomMutation, createClassroomMutationData] = useCreateClassroomMutation()
  const [deleteClassroomMutation, deleteClassroomMutationData] = useDeleteClassroomMutation()

  const isPending =
    editClassroomMutationData.loading || createClassroomMutationData.loading || deleteClassroomMutationData.loading

  const editClassroom = async (classroomInput: ClassroomInput, shouldClearLearningStore = false) => {
    try {
      const result = await editClassroomMutation({
        variables: { classroomId: classroomId || '', classroomInput },
        update: (cache) => {
          const { classroom } =
            cache.readQuery<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>({
              query: GetClassroomDetailDocument,
              variables: { classroomId: classroomId || '' }
            }) || {}

          if (classroom) {
            cache.writeQuery<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>({
              query: GetClassroomDetailDocument,
              variables: { classroomId: classroomId || '' },
              data: {
                classroom: {
                  ...classroom!,
                  ...classroomInput
                }
              }
            })
          }

          if (shouldClearLearningStore) {
            learningPlanStore.clearStore()
          }
        }
      })

      return result.data?.editClassroom
    } catch (err) {
      LogUtils.logError(err, 'useGetClassroomDetail', 'editClassroom')
      return false
    }
  }

  const createClassroom = async (classroomInput: ClassroomInput): Promise<string | undefined> => {
    try {
      const result = await createClassroomMutation({
        variables: { classroomInput },
        update: (cache, { data }) => {
          if (data?.createClassroom) {
            const { classrooms = [] } =
              cache.readQuery<GetClassroomsQuery, GetClassroomsQueryVariables>({
                query: GetClassroomsDocument,
                variables: {}
              }) || {}

            cache.writeQuery<GetClassroomsQuery, GetClassroomsQueryVariables>({
              query: GetClassroomsDocument,
              data: {
                classrooms: [...classrooms, data.createClassroom]
              }
            })
          }
        }
      })

      return result.data?.createClassroom.id
    } catch (err) {
      LogUtils.logError(err, 'useGetClassroomDetail', 'createClassroom')
    }
  }

  const deleteClassroom = async (classroomId: string): Promise<boolean | undefined> => {
    try {
      const result = await deleteClassroomMutation({
        variables: { classroomId },
        update: (cache, { data }) => {
          if (data?.deleteClassroom) {
            const { classrooms = [] } =
              cache.readQuery<GetClassroomsQuery, GetClassroomsQueryVariables>({
                query: GetClassroomsDocument,
                variables: {}
              }) || {}

            cache.writeQuery<GetClassroomsQuery, GetClassroomsQueryVariables>({
              query: GetClassroomsDocument,
              data: {
                classrooms: [...classrooms.filter((c) => c.id !== classroomId)]
              }
            })

            if (classrooms.length > 0) {
              navigate(Pages.CLASSROOM_LEARNING_PLAN(''), { replace: true })
            }
          }
        }
      })

      return result.data?.deleteClassroom
    } catch (err) {
      LogUtils.logError(err, 'useGetClassroomDetail', 'deleteClassroom')
    }
  }

  return {
    data: data?.classroom,
    isPending,
    loading: restOfResult.loading,
    error: restOfResult.error,
    refetch: restOfResult.refetch,
    actions: {
      editClassroom,
      createClassroom,
      deleteClassroom
    }
  }
}
