import classNames from 'classnames'
import * as Tooltip from '@radix-ui/react-tooltip'
import { Plus } from '@untitled-ui/icons-react'

import { Body } from '@app/components'
import { RouteType } from '@app/config/router/navMenu'

type Props = RouteType & {
  isActive: boolean
  isExpanded: boolean
  onClick?: () => void
}

export const NewItem = (props: Props) => {
  const { label, isActive, isExpanded, onClick } = props

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        {!isExpanded && (
          <Tooltip.Trigger onClick={onClick}>
            <Body
              className={classNames(
                'm-auto flex size-12 items-center justify-center rounded-[10px] text-sm text-primary-200 transition-all hover:opacity-60'
              )}
              weight={isActive ? 'bold' : 'regular'}
              style={{
                background: 'radial-gradient(271.98% 55.21% at 50% 11.46%, #B164EE 0%, #8336E6 100%)',
                boxShadow:
                  '4px 4px 19px 0px #AA75F0 inset, 0px 0px 10px 0px rgba(170, 117, 240, 0.40), 3px 6px 8px 0px rgba(35, 35, 35, 0.20)'
              }}
            >
              <Plus className="mx-auto size-8" />
            </Body>
          </Tooltip.Trigger>
        )}

        <Tooltip.Portal>
          <Tooltip.Content sideOffset={15} side="right" className="z-20 rounded-lg bg-white px-[10px] py-2 shadow-2xl">
            <Body size="m" weight="bold">
              {label}
            </Body>
            <Tooltip.Arrow className="fill-white" />
          </Tooltip.Content>
        </Tooltip.Portal>

        {isExpanded && (
          <div
            className={classNames(
              'flex w-full cursor-pointer flex-row items-center justify-center gap-2 rounded-md px-3 text-sm',
              'hover:opacity-60'
            )}
            style={{
              background: 'radial-gradient(271.98% 55.21% at 50% 11.46%, #B164EE 0%, #8336E6 100%)',
              boxShadow:
                '4px 4px 19px 0px #AA75F0 inset, 0px 0px 10px 0px rgba(170, 117, 240, 0.40), 3px 6px 8px 0px rgba(35, 35, 35, 0.20)'
            }}
            onClick={onClick}
          >
            <Plus width={24} height={24} />
            <Body className={classNames('py-3 text-primary-200 transition-all')} weight={isActive ? 'bold' : 'regular'}>
              {label}
            </Body>
          </div>
        )}
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
