export enum TutorialElementIds {
  TUTOR_STEP_1 = 'tutor-step-1',
  TUTOR_STEP_2 = 'tutor-step-2',
  TUTOR_STEP_3 = 'tutor-step-3',
  TUTOR_STEP_4 = 'tutor-step-4',
  TUTOR_STEP_5 = 'tutor-step-5',
  TUTOR_STEP_6 = 'tutor-step-6'
}

export enum TutorialAction {
  ON_CLICK = 'ON_CLICK'
}

export type TutorStepType = {
  elementId: string
  title?: string
  description: string
  continueButton?: string
  skipButton?: string
  isHidden?: boolean
  withOverlay?: boolean
  action?: TutorialAction
  dialogPosition?: DialogPosition
  floppyPosition?: FloppyPosition
}

export type DialogPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

export type FloppyPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
