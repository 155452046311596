import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Constants } from '@app/config/constants/Constants'
import { Pages } from '@app/config/router/PagesEnum'
import { LogUtils } from '@app/utils/LogUtils'

import { MaintenanceStatus } from '../model/enums'

export const useCheckMaintenance = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isMaintenance, setIsMaintenance] = useState(false)
  const [lastMaintenanceTimestamp, setLastMaintenanceTimestamp] = useState<number>(
    Date.now() - Constants.MAINTENANCE_CHECK_INTERVAL
  )

  useEffect(() => {
    const shouldCheckMaintenance = Date.now() - lastMaintenanceTimestamp > Constants.MAINTENANCE_CHECK_INTERVAL

    if (isMaintenance || shouldCheckMaintenance) {
      checkMaintenanceStatus()
    }
  }, [location.pathname])

  const checkMaintenanceStatus = async () => {
    setLastMaintenanceTimestamp(Date.now())

    try {
      const result = await fetch(Constants.MAINTENANCE_URL)

      const { status }: { status: MaintenanceStatus } = await result.json()
      if (status === MaintenanceStatus.MAINTENANCE) {
        setIsMaintenance(true)
        if (location.pathname !== Pages.MAINTENANCE) {
          navigate(Pages.MAINTENANCE)
        }
      } else if (status === MaintenanceStatus.UP) {
        setIsMaintenance(false)
        if (location.pathname === Pages.MAINTENANCE) {
          navigate(Pages.ROOT)
        }
      }
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        console.info('Fetch request timed out')
      } else {
        LogUtils.logError(error, 'MainLayout', 'checkMaintenanceStatus', undefined, false)

        if (location.pathname !== Pages.MAINTENANCE) {
          navigate(Pages.MAINTENANCE)
        }
      }
    }
  }

  return { isMaintenance, checkMaintenanceStatus }
}
