import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

type DialogStateType<T> = {
  isOpen: boolean
  isWithoutAnimation?: boolean
  data?: T
}

type NavigationStoreType = {
  newLearningActivity: DialogStateType<{ packageId?: string }>
  sideMenu: DialogStateType<null>
  sideNavigation: DialogStateType<null>
  newLearningLesson: DialogStateType<null>
  addStudentQR: DialogStateType<null>
  learningActivityDetail: DialogStateType<{ id: string; learningPlanLessonId: string }>
  assignLearningActivityTemplate: DialogStateType<{ libraryItemActivityId: string }>
  activityAssignedToClass: DialogStateType<string[]>
}

const initialState: NavigationStoreType = {
  newLearningActivity: { isOpen: false },
  sideMenu: { isOpen: false },
  sideNavigation: { isOpen: true },
  newLearningLesson: { isOpen: false },
  learningActivityDetail: { isOpen: false },
  addStudentQR: { isOpen: false },
  assignLearningActivityTemplate: { isOpen: false },
  activityAssignedToClass: { isOpen: false }
}

type DialogKeyType = keyof NavigationStoreType

const useStore = createWithEqualityFn<NavigationStoreType>(() => initialState, shallow)

export const dialogStateStore = {
  setIsOpen: <T>(key: DialogKeyType, data?: NavigationStoreType[DialogKeyType]['data'] | T): void => {
    useStore.setState({ [key]: { isOpen: true, isWithoutAnimation: false, data } })
  },
  setIsClosed: (key: DialogKeyType, replace?: false): void => {
    useStore.setState((prevState) => ({ ...prevState, [key]: { ...prevState[key], isOpen: false } }), replace)
  },

  clearStore: (): void => {
    useStore.setState(initialState)
  },
  useStore
}
