import * as Dialog from '@radix-ui/react-dialog'

import { Button } from '@app/components'
import { useConfirmReleaseNotesMutation } from '@app/data'
import { useTranslation } from '@app/locales'

type Props = {
  data: any // FIXME any
  onClose: () => void
}

export const ReleaseNotesDialog = ({ data, onClose }: Props) => {
  const { t } = useTranslation('common')

  const [confirmReleaseNotes] = useConfirmReleaseNotesMutation()

  const handleOnClose = () => {
    if (data && data.length > 0) {
      confirmReleaseNotes({ variables: { id: data[0].id } })
      onClose()
    }
  }

  return (
    <Dialog.Root open={true}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-30 bg-black opacity-70" onClick={handleOnClose} />
        {/* Erase Error end warning from console */}
        <Dialog.DialogTitle></Dialog.DialogTitle>
        <Dialog.Description></Dialog.Description>
        <Dialog.Content className="fixed left-[50%] top-[50%] z-40 flex max-h-[85vh] w-[90vw] max-w-[700px] translate-x-[-50%] translate-y-[-50%] flex-col rounded-2xl bg-primary-900 p-10 shadow-2xl">
          <div className="flex h-[820px] w-full flex-col gap-6">
            {<iframe src={data && data.length > 0 && data[0].url} className="h-[800px] w-full" />}

            <div className="flex w-full justify-center">
              <Button onClick={handleOnClose}>{t('great')}</Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
