import { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useClickAway } from 'react-use'
import { default as classNames, default as classnames } from 'classnames'
import { motion } from 'framer-motion'
import {
  ChevronLeft,
  ChevronRight,
  Home02,
  LogOut01,
  PlayCircle,
  Settings01,
  Settings04,
  TrendUp01
} from '@untitled-ui/icons-react'

import { logout } from '@app/app/auth/actions/authActions'
import { EnglishFlagRoundIcon } from '@app/assets/flagIcons/round/EnglishFlagRoundIcon'
import { FranceFlagRoundIcon } from '@app/assets/flagIcons/round/FranceFlagRoundIcon'
import { GermanFlagRoundIcon } from '@app/assets/flagIcons/round/GermanFlagRoundIcon'
import { SpanishFlagRoundIcon } from '@app/assets/flagIcons/round/SpanishFlagRoundIcon'
import { Body, Divider } from '@app/components'
import { ImageContent } from '@app/config/constants/ImageContent'
import { RouteType } from '@app/config/router/navMenu'
import { Pages } from '@app/config/router/PagesEnum'
import { Language } from '@app/data'
import { useTranslation } from '@app/locales'
import { createClassStore } from '@app/modules/classroom/store/createClassStore'
import { imagesIdToPicture } from '@app/modules/classroom/utils/imagesIdToPicture'
import { TutorialElementIds } from '@app/modules/floppyTutor/model/types'
import { dialogStateStore } from '@app/modules/learningPlan/store/dialogStateStore'
import { libraryDataStore } from '@app/modules/library/store/libraryDataStore'

import { useClassrooms } from '../../data/useClassrooms'
import { checkUrlIsActive } from '../../utils/checkUrlIsActive'
import { ClassroomNavigationItem } from './components/ClassroomNavigationItem'
import { CollapsibleWrapper } from './components/CollapsibleWrapper'
import { NavigationItem } from './components/NavigationItem'
import { NewItem } from './components/NewItem'
import { UserInfo } from './components/UserInfo'

export const SideNavigation = () => {
  const isExpanded = dialogStateStore.useStore((store) => store.sideMenu.isOpen)
  const location = useLocation()
  const { t } = useTranslation(['common', 'classroom'])
  const navigationDiv = useRef<HTMLDivElement>(null)
  const classroomNavigationDiv = useRef<HTMLDivElement>(null)
  const isLibraryVisible = libraryDataStore.useStore((store) => store.isLibraryVisible)

  useClickAway(navigationDiv, () => hideMenu())

  const { classrooms } = useClassrooms()

  const navMenu: RouteType[] = [
    {
      to: isLibraryVisible
        ? Pages.LIBRARY(Language.ENGLISH.toLowerCase())
        : Pages.DICTIONARY(Language.ENGLISH.toLowerCase()),
      label: t('languages.english'),
      // TODO: fix types
      Icon: EnglishFlagRoundIcon as any,
      iconPosition: 'right',
      position: 'middle'
    },
    {
      to: isLibraryVisible
        ? Pages.LIBRARY(Language.GERMAN.toLowerCase())
        : Pages.DICTIONARY(Language.GERMAN.toLowerCase()),
      label: t('languages.german'),
      // TODO: fix types
      Icon: GermanFlagRoundIcon as any,
      iconPosition: 'right',
      position: 'middle'
    },
    {
      to: isLibraryVisible
        ? Pages.LIBRARY(Language.FRENCH.toLowerCase())
        : Pages.DICTIONARY(Language.FRENCH.toLowerCase()),
      label: t('languages.french'),
      // TODO: fix types
      Icon: FranceFlagRoundIcon as any,
      iconPosition: 'right',
      position: 'middle'
    },
    {
      to: isLibraryVisible
        ? Pages.LIBRARY(Language.SPANISH.toLowerCase())
        : Pages.DICTIONARY(Language.SPANISH.toLowerCase()),
      label: t('languages.spanish'),
      // TODO: fix types
      Icon: SpanishFlagRoundIcon as any,
      iconPosition: 'right',
      position: 'middle'
    },
    {
      to: Pages.SETTINGS,
      label: t('routes.settings'),
      Icon: Settings01,
      position: 'bottom'
    }
  ]

  const topRoutes = useMemo(() => navMenu.filter((route) => route.position === 'top'), [isLibraryVisible])
  const middleRoutes = useMemo(() => navMenu.filter((route) => route.position === 'middle'), [isLibraryVisible])
  const classroomRoutes: RouteType[] =
    classrooms?.map((classroom) => ({
      Icon: () => <img src={imagesIdToPicture[classroom.subject][classroom.pictureId || 1]} />,
      label: classroom.name,
      to: Pages.CLASSROOM_DETAIL(classroom.id),
      children: [
        {
          label: t('routes.learningPlan'),
          to: Pages.CLASSROOM_LEARNING_PLAN(classroom.id),
          Icon: Home02
        },
        {
          label: t('routes.activities'),
          to: Pages.CLASSROOM_ACTIVITIES(classroom.id),
          Icon: PlayCircle
        },
        {
          label: t('routes.performance'),
          to: Pages.CLASSROOM_PERFORMANCE(classroom.id),
          Icon: TrendUp01
        },
        {
          id: TutorialElementIds.TUTOR_STEP_2,
          label: t('routes.settings'),
          to: Pages.CLASSROOM_MANAGE_CLASS(classroom.id),
          Icon: Settings04
        }
      ]
    })) || []

  const newClassRoute: RouteType = {
    label: t('create'),
    to: Pages.NEW_CLASS,
    position: 'top'
  }

  const bottomRoutes = useMemo(() => navMenu.filter((route) => route.position === 'bottom'), [])

  const showMenu = () => dialogStateStore.setIsOpen('sideMenu')
  const hideMenu = () => dialogStateStore.setIsClosed('sideMenu')

  //#region Handler
  const handleOnNewClassClick = () => {
    hideMenu()
    createClassStore.setDialogOpen(true)
  }

  const handleOnArrowClick = isExpanded ? hideMenu : showMenu
  //#endregion

  useEffect(() => {
    if (isExpanded) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    // Cleanup on unmount
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isExpanded])

  useEffect(() => {
    if (classroomNavigationDiv.current) {
      classroomNavigationDiv.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [location.pathname])

  return (
    <motion.div
      id="side-navigation"
      ref={navigationDiv}
      animate={isExpanded ? { translateX: 0, width: 330 } : { translateX: 0, width: 80 }}
      initial={{ translateX: '-40vw', width: 80 }}
      transition={{ duration: 0.1 }}
      className={classNames(
        'fixed left-0 z-30 m-auto flex h-screen max-w-[95vw] flex-col items-center border-primary-600 bg-primary-900 shadow-2xl',
        isExpanded && 'border-r-2'
      )}
    >
      <div className={classNames('w-full py-4', isExpanded && 'pl-6')}>
        {isExpanded ? (
          <img src={ImageContent.logo} className="w-28" />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="33"
            viewBox="0 0 20 33"
            fill="none"
            className="mx-auto"
          >
            <path
              d="M18.2225 4.61157C18.9747 3.50456 21.0023 0.566733 19.3986 0.458354C8.34073 -0.288683 3.65565 0.0712882 2.09395 0.202891C0.53226 0.334493 0.402437 1.81309 0.402437 1.81309C0.402437 1.81309 0.0129687 8.82672 0.00151374 16.421C-0.0061229 24.3558 0.00151366 31.7991 0.364254 32.999C0.364254 32.999 2.87289 33.1035 4.76678 31.1798C6.2712 29.6509 7.13795 27.6575 7.13795 23.6359C7.13795 18.2556 7.11886 18.2556 7.11886 18.2556C7.11886 17.6325 7.61906 17.1254 8.23381 17.1254H10.6584C11.4068 17.1254 11.2579 15.7126 11.8307 13.8818C12.4569 11.8729 13.4878 10.9169 12.2736 10.9169H9.43658C9.20366 10.9169 8.52019 10.9169 8.34836 10.9169C7.74507 10.913 7.25632 10.4176 7.2525 9.806C7.24487 8.94284 7.23723 8.47062 7.22959 7.47586C7.22959 6.85268 7.72216 6.34563 8.33691 6.34176H8.86383C8.86383 6.34176 11.5137 6.27209 14.3699 6.27209C16.027 6.27209 17.4703 5.71858 18.2225 4.61157Z"
              fill="#FEFEFE"
            />
          </svg>
        )}
        <div
          id="side-navigation-toggle"
          className="absolute -right-4 top-8 z-20 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-2 border-primary-600 bg-primary-700 p-1 hover:bg-primary-600"
          onClick={handleOnArrowClick}
        >
          {isExpanded ? (
            <ChevronLeft className="rounded-md text-primary-200" />
          ) : (
            <ChevronRight className="rounded-md text-primary-200" />
          )}
        </div>
      </div>
      <aside className={classnames('relative z-10 flex h-full w-full flex-col overflow-hidden')}>
        <nav className="flex h-full flex-col justify-between">
          {/* Top routes */}
          <div id="side-navigation-scroll" className="no-scrollbar overflow-y-auto px-4 pt-6">
            <div className="flex flex-col gap-3">
              {topRoutes.map((route, index) => (
                <NavigationItem {...route} key={index} isExpanded isActive={checkUrlIsActive(route, location)} />
              ))}

              <NewItem
                {...newClassRoute}
                isExpanded={isExpanded}
                isActive={checkUrlIsActive(newClassRoute, location)}
                onClick={checkUrlIsActive(newClassRoute, location) ? undefined : handleOnNewClassClick}
              />
              {classroomRoutes.map((route, index) => {
                const isActive = checkUrlIsActive(route, location)

                return (
                  <div key={index} ref={isActive ? classroomNavigationDiv : undefined}>
                    <ClassroomNavigationItem
                      {...route}
                      key={index}
                      location={location}
                      isExpanded={isExpanded}
                      isActive={isActive}
                    />
                  </div>
                )
              })}
            </div>

            <div className="group flex w-full flex-col gap-3">
              <Divider className="mt-4 h-[2px] bg-primary-600" />

              {/* Dictionaries */}
              <div className="flex items-center justify-start gap-3">
                <img
                  src={ImageContent.classrooms.progress.books}
                  className={classNames('size-10', !isExpanded && 'mx-auto')}
                />
                {isExpanded && (
                  <Body size="m" className="text-white">
                    {t('routes.dictionaries')}
                  </Body>
                )}
              </div>

              {middleRoutes.map((route, index) => {
                if (route.children) {
                  return (
                    <CollapsibleWrapper
                      key={index}
                      routes={route.children}
                      {...route}
                      isExpanded={isExpanded}
                      isActive={checkUrlIsActive(route, location)}
                    />
                  )
                } else {
                  return (
                    <NavigationItem
                      {...route}
                      key={index}
                      isExpanded={isExpanded}
                      isActive={checkUrlIsActive(route, location)}
                      className="size-12 border-[1px] border-primary-600 bg-primary-700"
                      iconClassName="mx-[10px]"
                    />
                  )
                }
              })}
            </div>
          </div>

          {/* Bottom routes */}
          <div className="flex w-full flex-col px-4 pb-4 pt-2">
            <Divider className="my-2 bg-primary-700" />
            {bottomRoutes.map((route, index) => (
              <NavigationItem
                {...route}
                key={index}
                isExpanded={isExpanded}
                isActive={checkUrlIsActive(route, location)}
                className="size-12"
                iconClassName="mx-3"
              />
            ))}

            <div onClick={logout}>
              <NavigationItem
                isExpanded={isExpanded}
                label={t('logout')}
                isActive={false}
                Icon={LogOut01}
                to="#"
                className="size-12"
                iconClassName="mx-3"
              />
            </div>

            <Divider className="my-2 bg-primary-700" />
            <UserInfo isExpanded={isExpanded} />
          </div>
        </nav>
      </aside>
    </motion.div>
  )
}
