export enum SocialGameTypeSorted {
  FLIPCARDS = 'FLIPCARDS',
  QUIZ = 'QUIZ',
  HANGMAN = 'HANGMAN',
  MATCH = 'MATCH',
  PEXESO = 'PEXESO'
}

export enum MaintenanceStatus {
  UP = 'UP',
  MAINTENANCE = 'MAINTENANCE'
}
