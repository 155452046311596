import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames'
import * as RadixAlertDialog from '@radix-ui/react-alert-dialog'
import { XClose } from '@untitled-ui/icons-react'

import { Divider } from '..'

type Props = React.HTMLProps<HTMLDivElement> & {
  title: string
  description?: string
  actionButtons?: ReactNode
  withDivider?: boolean
  isOpen?: boolean
  showOverlay?: boolean
  onAbort?: () => void
}

/**
 * @group Components
 * @category Component
 */
export const AlertDialog = forwardRef(
  (
    {
      className,
      title,
      description,
      disabled,
      children,
      actionButtons,
      withDivider,
      isOpen,
      showOverlay = true,
      onAbort,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <RadixAlertDialog.Root open={isOpen}>
        {children && (
          <RadixAlertDialog.Trigger asChild disabled={disabled} onAbort={onAbort}>
            <div>{children}</div>
          </RadixAlertDialog.Trigger>
        )}
        <RadixAlertDialog.Portal>
          {showOverlay && <RadixAlertDialog.Overlay className="fixed inset-0 z-50 bg-black opacity-50" />}
          <RadixAlertDialog.Content
            {...restProps}
            ref={ref}
            className={classNames(
              'fixed left-[50%] top-[50%] z-50 max-h-[85vh] w-[90vw] max-w-[700px] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-primary-900 p-[25px] focus:outline-none md:w-auto',
              className
            )}
          >
            <div>
              <div className="flex justify-between">
                <RadixAlertDialog.Title className="m-0 text-[1.75rem] font-bold" asChild>
                  <div className="flex w-full justify-between text-primary-100">
                    <div className="w-full text-center">{title}</div>

                    <RadixAlertDialog.Action asChild className="cursor-pointer">
                      <XClose onClick={onAbort} />
                    </RadixAlertDialog.Action>
                  </div>
                </RadixAlertDialog.Title>
              </div>
              {withDivider && <Divider className="px-[-15px]" />}
              <RadixAlertDialog.Description className="mb-7 mt-4 text-center text-base text-primary-200">
                {description}
              </RadixAlertDialog.Description>
              <RadixAlertDialog.Action asChild className="m-auto w-full">
                {actionButtons}
              </RadixAlertDialog.Action>
            </div>
          </RadixAlertDialog.Content>
        </RadixAlertDialog.Portal>
      </RadixAlertDialog.Root>
    )
  }
)

AlertDialog.displayName = 'AlertDialog'
