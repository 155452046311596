import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { LearningPlanLesson } from '../model/types'

type LearningPlanStoreType = {
  learningPlanMap: LearningPlanLesson[] | null
  activePageIndex: number | undefined
  redirectPageIndex: number | undefined
}

const initialState: LearningPlanStoreType = {
  learningPlanMap: null,
  activePageIndex: undefined,
  redirectPageIndex: undefined
}

const useStore = createWithEqualityFn<LearningPlanStoreType>(() => initialState, shallow)

export const learningPlanStore = {
  setLearningPlanMap: (learningPlanMap: LearningPlanLesson[]): void => {
    useStore.setState({ learningPlanMap })
  },
  setActiveIndex: (activePageIndex?: number): void => {
    useStore.setState({ activePageIndex })
  },
  setRedirectPageIndex: (newIndex?: number): void => {
    useStore.setState({ redirectPageIndex: newIndex })
  },

  clearStore: (): void => {
    useStore.setState(initialState, true)
  },

  useStore
}
