import { forwardRef } from 'react'
import classnames from 'classnames'

import { Typography, TypographyProps, WeightTextType } from '.'

type Props = Omit<TypographyProps, 'variant'> & {
  size?: HeadingSizeType
}

export type HeadingSizeType = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs'

const sizeToHeadingType: Record<HeadingSizeType, React.ElementType> = {
  xxl: 'h1',
  xl: 'h1',
  l: 'h2',
  m: 'h3',
  s: 'h4',
  xs: 'h5'
}

const sizeToStyle: Record<HeadingSizeType, React.ComponentProps<'h1'>['className']> = {
  xxl: 'text-5xl tracking-[-0.48px] leading-[56px]',
  xl: 'text-4xl tracking-[-0.36px]',
  l: 'text-4xl leading-[44px]',
  m: 'text-2xl',
  s: 'text-xl',
  xs: 'text-lg'
}

const weightToStyle: Record<WeightTextType, React.ComponentProps<'h1'>['className']> = {
  bold: 'font-bold',
  semibold: 'font-semibold',
  medium: 'font-medium',
  regular: 'font-normal'
}

/**
 * @group Components
 * @category Atoms
 */
export const Heading = forwardRef<HTMLHeadingElement, Props>(
  ({ className, weight = 'bold', size = 'xl', children, ...props }, ref) => {
    const HeadingComponent = sizeToHeadingType[size]
    const sizeStyle = sizeToStyle[size]
    const weightStyle = weightToStyle[weight]

    return (
      <Typography
        Component={HeadingComponent}
        className={classnames(sizeStyle, weightStyle, className)}
        ref={ref}
        {...props}
      >
        {children}
      </Typography>
    )
  }
)

Heading.displayName = 'Heading'
