import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useTitle } from 'react-use'

import { AuthProvider } from './app/auth/view/AuthProvider'
import { KeycloakProvider } from './app/auth/view/KeycloakProvider'
import { RoleProvider } from './app/auth/view/RoleProvider'
import { Constants } from './config/constants/Constants'
import { router } from './config/router/router'
import { GraphqlProvider } from './services/graphql/GraphqlProvider'
import { initializeSentry } from './services/Sentry/initializeSentry'

import './App.css'

initializeSentry()

function App() {
  useTitle(Constants.IS_DEVELOPMENT ? '(DEV) Foxino Teacher' : 'Foxino Teacher')

  return (
    <KeycloakProvider>
      <AuthProvider>
        <GraphqlProvider>
          <ToastContainer />
          <RoleProvider>
            <RouterProvider router={router} />
          </RoleProvider>
        </GraphqlProvider>
      </AuthProvider>
    </KeycloakProvider>
  )
}

export default App
